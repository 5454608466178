body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.termsAndConditionPage ol {
  counter-reset: item;
  margin: 0 0 1.5em;
  padding: 0;
}

.termsAndConditionPage li {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 2rem;
  text-indent: -1.2rem;
}

.termsAndConditionPage li:before {
  content: counters(item, ".") ". ";
  display: inline-block;
  counter-increment: item;
  font-weight: bold;
  padding-right: .5rem;
  text-align: right;
  width: 1.5rem;
}

.Paragraphg {
  margin-left: 90px;
  ;
}

.manageLine {
  line-height: 30px;
}

.input-datetime {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 108px;
}

.input-hour {
  width: 50px !important ;
}

.input-min {
  width: 50px !important ;
}

.input-align-right {
  text-align: right;
}

input.n-NumberArrows::-webkit-outer-spin-button,
input.n-NumberArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input.n-NumberArrows[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.secondInputGrp {
  width: 240%;
}

.time-sparater {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.react-datepicker__tab-loop {
  margin-left: -1rem;
}
/* CSS Variables */
:root {
  --white: #fff;
  --black: #000;
  --primary-colour: #44cb6f;
  --secondary-colour: #323f6c;
  --dark-text-colour: #43485a;
  --secondary-text-colour: #6c6c6c;
  --background-grey-colour: #f5f5f5;
  --error-colour: #de5656;
  --success-colour: #5bce7f;
  --faint-error-colour: #ffd9d9;
  --faint-success-colour: #e8ffef;
  --invalid-colour: #dc3545;
  --bright-green: #44cb6f;
  --light-green: #a3e7b0;
  --darkgreen: #32ac59;
  --green-transparent: rgba(34, 206, 89, 0.15);
  --darkblue: #323f6c;
  --darkgrey: #dfe0e4;
  --grey-transparent: rgba(245, 245, 245, 0.45);
  --invalid-colour: #dc3545;
  --disabled-grey-color: #e9ecef;
  --warning-yellow: #856404;;
  --faint-warning-colour: #fff3cd;
  --voluntary-data-colour: #E3F7EA
}

/* Font faces */
@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: 200;
}

#root {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-weight: 400;
}

input[type="checkbox"]:after {
  width: 20px !important;
  height: 20px !important;
  top: -3px !important;
  left: -3px !important;
  position: relative !important;
  background-color: #d1d3d1 !important;
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid white !important;
}

input[type="checkbox"]:checked:after {
  width: 20px !important;
  height: 20px !important;
  top: -3px !important;
  left: -3px !important;
  position: relative !important;
  background-color: var(--primary-colour) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid var(--grey-transparent) !important;
}

input[type="checkbox"].form-check-input.is-invalid:after {
  border: 2px solid #dc3545 !important;
}

input[type="radio"]:after {
  width: 20px !important;
  height: 20px !important;
  border-radius: 10px !important;
  top: -3px !important;
  left: -3px !important;
  position: relative !important;
  background-color: #d1d3d1 !important;
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid white !important;
}

input[type="radio"]:checked:after {
  background-color: var(--primary-colour) !important;
  border: 2px solid var(--grey-transparent) !important;
}

input[type="radio"]:focus {
  outline: none !important;
  border: 1px solid var(--primary-colour);
  box-shadow: 0 0 10px #719ece;
}

select,
input {
  font-size: 13px !important;
}

/* Bootstrap overrides */

.alert-primary{
  --bs-alert-color: var(--darkblue);
  --bs-alert-bg: var(--light-green);
  --bs-alert-border-color: var(--primary-colour);
}

.btn-primary {
  color: var(--white) !important;
  background-color: var(--primary-colour) !important;
  border-color: var(--primary-colour) !important;
}

.btn-primary:hover {
  color: var(--white);
  background-color: var(--darkgreen) !important;
  border-color: var(--darkgreen) !important;
}

.btn-secondary {
  color: var(--dark-text-colour) !important;
  background-color: transparent !important;
  border-color: var(--primary-colour) !important;
}

.btn-secondary:hover {
  color: var(--dark-text-colour);
  background-color: #00000008 !important;
  border-color: var(--primary-colour) !important;
}

.btn-primary-outline {
  color: var(--white) !important;
  background-color: var(--white) !important;
  border-color: var(--primary-colour) !important;
}

.btn-primary-outline:hover {
  color: var(--white);
  background-color: var(--grey-transparent) !important;
  border-color: var(--darkgreen) !important;
}

.btn-outline-primary {
  color: var(--white) !important;
  background-color:  var(--primary-colour) !important;
  border-color: var(--primary-colour) !important;
}

.btn-outline-primary:hover {
  color: var(--primary-colour) !important;
  background-color: transparent !important;
  border-color: var(--primary-colour) !important;
}


.btn-outline-secondary {
  color: var(--dark-text-colour);
  background-color: var(--white) !important;
  border-color: var(--primary-colour) !important;
}

.btn-outline-secondary:hover {
  color: var(--black) !important;
  background-color:  var(--primary-colour) !important;
  border-color: var(--primary-colour) !important;
}


.btn-transparent {
  color: var(--dark-text-colour) !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-transparent:hover {
  color: var(--dark-text-colour);
  background-color: var(--grey-transparent) !important;
  border: none !important;
}

.btn-back {
  color: var(--white) !important;
  background-color: transparent !important;
  border-color: var(--primary-colour) !important;
  font-size: 13px !important;
}

.btn-back:hover {
  color: var(--white);
  background-color: #00000008 !important;
  border-color: var(--primary-colour) !important;
}

.btn-back-nlu-text {
  color: var(--white) !important;
  background-color: transparent !important;
  border-color: var(--primary-colour) !important;
  font-size: 13px !important;
}

.btn-back:hover {
  color: var(--white);
  background-color: #00000008 !important;
  border-color: var(--primary-colour) !important;
}

.dropdown-item {
  font-size: 13px;
}

/* Custom CSS */
.darkblue {
  color: var(--darkblue) !important;
}

.primary-green {
  color: var(--primary-colour) !important;
}

.warning-yelllow {
  color: var(--warning-yellow) !important;
}

.secondary-grey {
  color: var(--secondary-text-colour) !important;
}

.bg-darkblue {
  background-color: var(--darkblue);
}

.bg-override-primary {
  background-color: var(--primary-colour) !important;
}

.bg-grey-transparent {
  background-color: var(--grey-transparent);
}

.bg-grey {
  background-color: var(--background-grey-colour);
}

.bg-faint-error {
  background-color: var(--faint-error-colour);
}

.bg-faint-warning {
  background-color: var(--faint-warning-colour);
}

.bg-faint-grey {
  background-color: var(--darkgrey);
}


.bg-faint-success {
  background-color: var(--faint-success-colour);
}

.transparent-grey {
  color: var(--background-grey-colour);
}

.dark-grey {
  color: var(--darkgrey);
}

.bg-white {
  background-color: var(--white);
}

.white-text {
  color: var(--white);
}

.error-red {
  color: var(--error-colour) !important;
}

.success-green {
  color: var(--success-colour) !important;
}

.subtitle-text-heavy {
  font-size: 36px;
  font-weight: 400;
  line-height: 54px;
}

.heading-text {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.02em;
}

.input-text {
  color: var(--secondary-text-colour);
  font-size: 13px;
  font-weight: 300;
  line-height: 19.5px;
}

.field-label {
  color: var(--darkblue);
  font-size: 13px !important;
  line-height: 20px;
  font-weight: 500;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  padding-right: 4px;
  color: var(--secondary-text-colour);
  font-weight: 400;
  font-size: 13px;
}

.override-select {
  padding-right: 4px !important;
  color: var(--secondary-text-colour) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.inputGroupAppend {
  background-color: transparent !important;
  border-left: none !important;
  padding-left: 0 !important;
  color: var(--secondary-colour) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-left: 0px !important;
}

.inputGroupWithAppend {
  border-right: none !important;
  text-align: right !important;
  padding-right: 4px !important;
  color: var(--secondary-text-colour) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.inputGroupWithAppendLeft {
  border-right: none !important;
  text-align: left !important;
  padding-right: 4px !important;
  color: var(--secondary-text-colour) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.upper-panel {
  height: 502px;
  background-color: var(--darkblue);
}

/* Custom CSS */
.invalidAppend {
  border-right: 1px solid var(--invalid-colour) !important;
  border-top: 1px solid var(--invalid-colour) !important;
  border-bottom: 1px solid var(--invalid-colour) !important;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.form-container {
  position: relative;
  top: -300px;
  width: 874px !important;
  background-color: #fff;
  border-radius: 5px;
  min-height: 400px;
}

.button-container {
  width: 874px !important;
}

.button-container-mid {
  width: 1300px !important;
}

.form-container-mid {
  position: relative;
  top: -300px;
  width: 1300px !important;
  background-color: #fff;
  border-radius: 5px;
  min-height: 400px;
}

.form-container-full {
  position: relative;
  top: -300px;
  background-color: #fff;
  border-radius: 5px;
  min-height: 400px;
}

.error-label {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: var(--error-colour);
}

.validation-error-tooltip {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  z-index: 10;
  border-radius: 8px;
  opacity: 1;
  background-color: var(--invalid-colour) !important;
}

.validation-error-tooltip > .react-tooltip {
  background-color: var(--invalid-colour) !important;
}

.datepicker-input {
  border-right: none;
  text-align: right;
  padding-right: 4px;
  color: var(--secondary-text-colour);
  font-weight: 400;
  font-size: 13px;
}

.datepicker-input-nested-label {
  background-color: transparent;
  border-left: none !important;
  padding-left: 0;
  color: var(--secondary-colour);
  font-weight: 500;
  font-size: 12px;
}

.datepicker-input-nested-label.redBorder{
  border-left: none !important;
}

.input-hour {
  width: 50px !important;
}

.input-min {
  width: 50px !important;
}

.input {
  text-align: right;
  color: var(--secondary-text-colour);
  font-weight: 400;
  font-size: 13px;
}

.time-sparater {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.redBorder {
  border: 1px solid var(--error-colour) !important;
}

.input-datetime {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--background-grey-colour);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 108px;
}

.datepicker-input.redBorder {
  border-right: none !important;
}

.datepicker-input-i.redBorder {
  border-left: none !important;
}

.align-bottom {
  height: 100%;
  align-self: flex-end;
}

.gray-border-top {
  border-top: 1px solid var(--darkgrey);
}

.info-icon {
  color: var(--primary-colour);
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

.form-control.is-invalid.errorLeft,
.was-validated .form-control:invalid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

.border-bottom-separator {
  border-bottom: 1px solid var(--primary-colour);
}

.green-underline-heading {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: var(--darkblue);
  border-bottom: 2px solid var(--primary-colour);
  padding-bottom: 4px;
  margin-bottom: 20px;
}

.green-underline-heading-light {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: var(--darkblue);
  border-bottom: 1px solid var(--primary-colour);
  padding-bottom: 4px;
  margin-bottom: 20px;
}

.faint-underline-grey {
  border-bottom: 1px solid var(--background-grey-colour);
}

.table-column-heading {
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: var(--darkblue);
}

.medium-heading {
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: var(--darkblue);
}

.table-text {
  color: var(--secondary-text-colour);
  font-weight: 400;
  font-size: 13px;
}

.verticle-table-cell {
  vertical-align: middle;
}

.table-grey-border-bottom tbody tr {
  border-top: 1px solid var(--darkgrey);
  border-bottom: none !important;
}

.table-grey-border-bottom tbody td {
  border: none;
}

.modal-header {
  background-color: var(--darkgrey);
}



.gray-frame {
  border-radius: 5px;
  background: var(--background-grey, #f5f5f5);
  padding: 18px 12px;
}

.primary-green-frame{
  border-radius: 5px;
  background: var(--primary-colour);
  padding: 18px 12px;
}

.clickable:hover {
  cursor: pointer;
}

.bg-hover:hover {
  filter: brightness(95%);
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold !important;
}

.lines {
  white-space: pre-line;
}

.lines p{
  margin-bottom: 0;
}

textarea {
  font-size: 13px !important;
}

.text-11 {
  font-size: 11px !important;
}

.text-13 {
  font-size: 13px !important;
}

.modal-dialog {
  font-family: "Poppins" !important;
}

.row-label {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: var(--secondary-text-colour) !important;
  vertical-align: top !important;
}

.location-point-icon{
  color: var(--darkgreen);
  font-size: 40px;
}

.text-13-400 {
  font-weight: 400 !important;
  font-size: 13px !important;
}

.modal-heading {
  background-color: var(--darkblue);
  color: white !important;
}
.modal-heading.btn-close {
  background-color: var(--darkblue);
  color: white !important;
}

:disabled {
  pointer-events: none !important;
  background-color: transparent !important;
  border:none !important;
  
  padding-top: 0px  !important;
  padding-bottom: 0px !important;
}

.inputGroupWithAppend:disabled + .inputGroupAppend {
  /*background-color: var(--disabled-grey-color) !important;*/
  background-color: transparent;
  border:none;
  padding-top: 0px  !important;
  padding-bottom: 0px !important;
}

.eovPelletsLabel{
  margin-right: 37px;
}

.secondary-badge {
  font-weight: 400 !important;
}

.hoverable:hover {
  filter: brightness(75%);
}

.consignmentsTable {
  width: 100% !important;
}

.consignmentsTable tbody tr:nth-child(odd){
  background-color: var(--grey-transparent);
}

.consignmentsTable th {
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: var(--darkblue);
}

.consignmentsTable td {
  padding: 10px;
  color: var(--secondary-text-colour);
  font-weight: 400;
  font-size: 13px;
}

.whiteInputGroupContainer .inputGroupAppend{
  background-color: white !important;
}

.dropdown-toggle-hide::after {
  background-image: none !important; 
}

.blue-link {
  color: var(--darkblue);
}

.blue-link:hover {
  color: #697089;
}

.voluntary-data-text {
  color: var(--voluntary-data-colour);
}

.voluntary-data-bg {
  background-color: var(--voluntary-data-colour);
}

#independent-observer-on-voyage:after, #chkAlternatingPenSpacing:after, #chkIOOnboard:after, #chkPenSpacing:after {
  border-color: var(--voluntary-data-colour) !important;
}

.lnc-number-mobile {
  border-top: 1px solid var(--background-grey-colour);
}

.consignment-info-table-cell-mobile {
  vertical-align: middle;
}

.consignment-info-table-header-mobile {
  background-color: var(--background-grey-colour);
  color: var(--secondary-colour);
  font-weight: 500;
}

.consignment-info-table-cell-subtitle-mobile {
  font-size: 10px;
}

.consignment-info-table-cell-subtitle-small-mobile {
  font-size: 8px;
}

.consignment-info-table-cell-border-right {
  border-right: 1px solid #00000011;
}

.accordion-button:not(.collapsed) {
  background-color: transparent!important;
}


.consignment-dashboard-edit-history-divider-borders {
  border-bottom: 1px solid var(--darkgrey);
  border-top: 1px solid var(--darkgrey);
}

.green-link {
  background: none!important;
  border: none;
  padding: 0!important;
  color: var(--bright-green) !important;
  cursor: pointer;
}

.green-link:hover {
  text-decoration: underline;
  font-weight: bold;
  color: var(--bright-green) !important;
}

.primary-spinner {
  color: var(--bright-green)
}
.login-left-panel {
    overflow: hidden;
    background-color: var(--secondary-colour);
}

.login-left-panel-text-container {
    position: absolute;
    top: 50%;
    left: 25%;
    max-width: 429px;
    margin: 0;
    transform: translate(-50%, -50%);
}

.login-left-panel-bg-image {
    position: relative;
    top: 0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    mix-blend-mode: multiply;
    filter: saturate(0%) brightness(108%)
}

.login-left-panel-text {
    font-family: 'Poppins';
    color: var(--white);
}

.login-left-panel-title {
    font-size: 43px;
    font-weight: 500;
    line-height: 64.5px;
    letter-spacing: 0.02em;
}

.login-left-panel-subtitle {
    font-size: 36px;
    font-weight: 200;
    line-height: 54px;
    letter-spacing: 0.02em;
}

.login-footer {
    width: -webkit-fill-available;
    text-align: center;
}

@media (min-height:783px){
    .login-footer {
        position: fixed;
        bottom: -12px;
        width: -webkit-fill-available;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .login-vertical-offset {
        vertical-align: middle;
        height: 100vh;
        padding-top: 15vh;
    }
}


.admin-tool-block {
    margin-left: 16px;
    margin-right: 8px;
}

.admin-tool-block:hover {
    cursor: pointer;
    text-decoration: underline;
}
  
.admin-tool-block svg {
    font-size: 80px;
    color: darkgray;
}

.admin-tool-icon-frame {
    border: 1px solid var(--secondary-text-colour); 
    border-radius: 10px;
}

.admin-tool-icon-frame:hover {
    border: 1px solid var(--secondary-text-colour); 
    border-radius: 10px;
    background-color: var(--grey-transparent);
}
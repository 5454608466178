.dashboard-consignment-group-header {
    background-color: var(--secondary-colour);
    padding: 30px 54px 32px 54px;
}

.dashboard-consignment-header-cell-border-end {
    border: 1px solid var(--darkgrey);
    border-radius: 0px;
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.dashboard-consignment-header-row {
    border: 1px solid var(--darkgrey);
    border-radius: 12px 12px 0 0;
    background-color: var(--grey-transparent);
}

.dashboard-consignment-cell-row {
    border: 1px solid var(--darkgrey);
    border-top: none;
}

.dashboard-reporting-status-icon-container {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 26px;
    border-radius: 3px;
}

.consignment-table {
    border-collapse: separate;
    border-spacing: 0;
  }
  
.consignment-table td {
    border: 1px solid var(--darkgrey);
    border-style: none solid solid none;
    padding: 0px;
}

.consignment-inner-table {
    border-collapse: separate;
    border-spacing: 0;
}

.consignment-table tr:first-child td:first-child { border-top-left-radius: 10px; }
.consignment-table tr:first-child td:last-child { border-top-right-radius: 10px; }

.consignment-table tr:last-child td:first-child { border-bottom-left-radius: 10px; }
.consignment-table tr:last-child td:last-child { border-bottom-right-radius: 10px; }

.consignment-table tr:first-child td { border-top-style: solid; }
.consignment-table tr td:first-child { border-left-style: solid; }

.consignment-table-cell-no-border-top {
    border-top: none!important;
}

.consignment-table-cell-no-border-bottom {
    border-bottom: none!important;
}

.consignment-table-cell-no-border-left {
    border-left: none!important;
}

.consignment-table-cell-no-border-right {
    border-right: none!important;
}

.consignment-table-cell-no-border {
    border: none!important;
}

.dashboard-tooltip {
    position: relative;
    display: inline-block;
}

.dashboard-tooltip .dashboard-tooltiptext {
    visibility: hidden;
    background-color: var(--darkgrey);
    color: var(--darkblue);
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 120px;
    bottom: 110%;
    left: 50%;
    margin-left: -60px;
}

.dashboard-tooltiptext {
    font-size: 12px;
    line-height: 14px;
}

.dashboard-tooltip:hover .dashboard-tooltiptext {
    visibility: visible;
}

.report-link-cell {
    background-color: white;
}

.report-link-cell:hover {
    background-color: #EDEDED;
}
.header-bar {
    height: 75px;
    background-color: var(--background-grey-colour);
}

.header-bar-content-container {
    line-height: 75px;
}

.header-logo {
    height: 45px;
}

.header-logo:hover {
    cursor: pointer;
}

.header-logged-in-user-container {
    display: flex;
    height: 100%;
    max-width: 361px !important;
    align-items: center;
    justify-content: start;
    border-left: 1px solid rgba(142, 149, 173, 0.25);
}
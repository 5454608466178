.tab-bar-button {
    font-size: 13px;
    line-height: 26px;
    background-color: var(--white) !important;
    border: 1px solid var(--primary-colour) !important;
    color: var(--dark-text-colour) !important;
}

.tab-bar-button-active {
    font-size: 13px;
    line-height: 26px;
    background-color: var(--primary-colour);
    border: 1px solid var(--primary-colour)
}
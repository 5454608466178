.jtht-column {
    max-width: 200px;
    width: 200px;
}

.jtht-row {
    padding-top: 16px!important;
    padding-bottom: 16px!important;
}

.jtht-column-background-alternate {
    background-color: #FFFFFF!important;
}

.jtht-column-background-alternate {
    background-color: #F5F5F5!important;
}

.jtht-selected-row-background {
    background-color: #F5F5F5!important;
}

.jtht-selected-row-background-alternate {
    background-color: #E3E3E3!important;
}

.jtht-header-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--darkblue);
}

.jtht-cell-text {
    font-size: 14px;
    font-weight: 400;
}
.cc-add-port-button {
    font-size: 12px !important;
    font-weight: 400 !important;
    display: inline-block !important;
    border-radius: 3px !important;
}

.cc-add-port-button:hover {
    cursor: pointer !important;
    background-color: var(--grey-transparent) !important;
}

.cc-feed-plan-row-label {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--secondary-text-colour) !important;
    vertical-align: top !important;
}

.cc-feed-input {
    border-right: none !important;
    text-align: right !important;
    padding-right: 4px !important;
    color: var(--secondary-text-colour) !important;
    font-weight: 400 !important;
    font-size: 13px !important;
}

.cc-feed-input-nested-label {
    background-color: transparent !important;
    border-left: none !important;
    padding-left: 0 !important;
    color: var(--secondary-colour) !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}

.cc-stockperson-badge {
    font-weight: 400 !important;
}

.cc-port-loading-feed {
    border-bottom: 1px solid var(--grey-transparent);
}
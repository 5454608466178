.vessel-is-loading {
    color: var(--primary-colour);
}

.vessel-is-loaded {
    color: var(--primary-colour);
}

.vessel-is-departed {
    color: var(--darkblue);
}

.vessel-default-state {
    color: var(--darkgrey);
}
.hc-table-header {
    position: relative;
    min-height: 60px;
    height: 100%;
}

.hc-table-header-contents {
    position: absolute;
    bottom: 0;
}

.hc-table-row-cell {
    position: relative;
    min-height: 60px;
    height: 100%;
}

.hc-table-row-cell-contents {
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translate(0%, -50%);
}
.dr-column-heading {
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;
    color: var(--darkblue);
    padding-bottom: 5px;
}

.dr-text {
    color: var(--secondary-text-colour);
    font-weight: 400;
    font-size: 13px;
  }
  